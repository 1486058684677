import { Field, FieldArray, Form, Formik } from "formik";
import SelectCityState from "./SelectCityState";
import React, { useEffect, useState } from "react";
import FormikInput from "../Components/FormikInput";
import { db } from "../FirebaseConfig";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { FaPlus } from "react-icons/fa6";


function LocationUpdate({
  locationId,
  locationDetails,
  setopeningUpdatePopUp,
  gettingLocationCategory,
  getLocationDetails,
}) {
  const [locCategory, setLocCategory] = useState([]);
  const [suitable, setSuitable] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [submittingSuitable, setSubmittingSuitable] = useState([]);
  const [submittingCategory, setSubmittingCategory] = useState([]);
  const [locationImage, setLocationImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);

  const location = locationDetails.filter((loc) => locationId === loc.id)[0];

  useEffect(() => {
    const fetchCategoriesAndSuitables = async () => {
      const querySnapshot = await getDocs(collection(db, "location_category"));
      const locArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocCategory(locArray);

      const suitableSnapshot = await getDocs(collection(db, "suitable_for"));
      const suitArray = suitableSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSuitable(suitArray);
    };

    fetchCategoriesAndSuitables();

    if (location) {
      setSubmittingCategory(location.locationCategory);
      setSubmittingSuitable(location.suitableFor);
    }
  }, [locationId, locationDetails, location]);

  const uploadFile = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `locationImages/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const updateLocation = async (values) => {
    try {
      const stateName = selectedState || location.state;
    const districtName = selectedDistrict || location.district;
    

     
      const imageURL = locationImage
        ? await uploadFile(locationImage)
        : location.image;

      const newAdditionalImageURLs = await Promise.all(
        additionalImages.map(uploadFile)
      );
      const allAdditionalImages = [
        ...(location.additionalImages || []),
        ...newAdditionalImageURLs,
      ];

      const updatedValues = {
        ...values,
        state: stateName,
        district: districtName,
        image: imageURL,
        suitableFor: submittingSuitable,
        locationCategory: submittingCategory,
        additionalImages: allAdditionalImages,
      };

      await updateDoc(doc(db, "adding_location", locationId), updatedValues);
      toast.success("Location updated successfully!");
      setopeningUpdatePopUp(false);
      gettingLocationCategory();
      getLocationDetails();
    } catch (error) {
      toast.error("Failed to update!");
    }
  };

  const deleteImage = async (deleteImage) => {
    try {
      const updatedAdditionalImages = location.additionalImages.filter(
        (imageUrl) => imageUrl !== deleteImage
      );

      await updateDoc(doc(db, "adding_location", locationId), {
        additionalImages: updatedAdditionalImages,
      });

      setAdditionalImages(updatedAdditionalImages);

      location.additionalImages = updatedAdditionalImages;

      toast.success("Image deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete image!");
    }
  };

  return (
    <div className="bg-black z-50 flex flex-col justify-center items-center fixed inset-0 bg-opacity-70">
      <div className="bg-white rounded p-4">
        <div className="flex items-center justify-between">
          <p className="text-2xl ml-10 sm:ml-[270px] text-[#074173] font-bold">
            Location Update
          </p>
          <button
            onClick={() => {
              setopeningUpdatePopUp(false);
            }}
            className="bg-red-500 rounded px-2 text-white"
          >
            X
          </button>
        </div>

        <div className="flex">
          <div className="ml-0 overflow-auto h-[600px]">
            <div className="mt-4">
              <Formik
                initialValues={{
                  location_name: location?.location_name || "",
                  location_desciption: location?.location_desciption || "",
                  stop_name: location?.stop_name || "",
                  stop_km: location?.stop_km || "",
                  station_name: location?.station_name || "",
                  station_km: location?.station_km || "",
                  airport_name: location?.airport_name || "",
                  airport_km: location?.airport_km || "",
                  location_link: location?.location_link || "",
                  crowd_allowed: location?.crowd_allowed || "",
                  staying_facilities: location?.staying_facilities || "",
                  termsAndCondition: location?.termsAndCondition || [''],
                  location_address: location?.location_address || "",
                  remarks: location?.remarks|| [""],
                  location_type: location?.location_type || "",
                }}
                onSubmit={updateLocation}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="md:w-auto w-auto sm:w-auto md:ml-0">
                      <div className="sm:flex sm:justify-center">
                        <div className="sm:w-auto mr-0 w-auto sm:mr-[120px]">
                          <div className="mt-2">
                            <p className="block text-[#074173] font-bold mb-2">
                              Location Category
                            </p>
                            {locCategory.map((loc) => (
                              <div className="text-[#074173]" key={loc.id}>
                                <input
                                  type="checkbox"
                                  value={loc.category}
                                  checked={submittingCategory.includes(
                                    loc.category
                                  )}
                                  onChange={(e) => {
                                    const { value, checked } = e.target;
                                    if (checked) {
                                      setSubmittingCategory([
                                        ...submittingCategory,
                                        value,
                                      ]);
                                    } else {
                                      setSubmittingCategory(
                                        submittingCategory.filter(
                                          (cat) => cat !== value
                                        )
                                      );
                                    }
                                  }}
                                />
                                {loc.category}
                              </div>
                            ))}
                          </div>


                          <div className="mt-7">
                            <FormikInput
                              name="location_name"
                              label="Location Name"
                              placeholder="Enter Location Name"
                            />
                            <div className="mt-3">
                              <FormikInput
                                name="location_desciption"
                                label="Location Description"
                                placeholder="Enter Location Description"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="mb-5">
                            <div className="mt-2">
                              <p className="block text-[#074173] font-bold mb-2">
                                Suitable for
                              </p>
                              {suitable.map((suit) => (
                                <div className="text-[#074173]" key={suit.id}>
                                  <input
                                    type="checkbox"
                                    value={suit.suitable_for}
                                    checked={submittingSuitable.includes(
                                      suit.suitable_for
                                    )}
                                    onChange={(e) => {
                                      const { value, checked } = e.target;
                                      if (checked) {
                                        setSubmittingSuitable([
                                          ...submittingSuitable,
                                          value,
                                        ]);
                                      } else {
                                        setSubmittingSuitable(
                                          submittingSuitable.filter(
                                            (suitFor) => suitFor !== value
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  {suit.suitable_for}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="">
                            <SelectCityState
                              onStateChange={(state) => setSelectedState(state)}
                              onDistrictChange={(district) =>
                                setSelectedDistrict(district)
                             
                              }
                              gettingInitialState = {location.state}
                              gettingInitialDistrict = {location.district}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <FormikInput
                          name="location_address"
                          placeholder="Enter Address"
                          type="text"
                          label="Address"
                        />
                        <FormikInput
                          name="location_link"
                          placeholder="Enter Location Link"
                          type="text"
                          label="Location Link"
                        />
                        <FormikInput
                          name="crowd_allowed"
                          placeholder="No of Crowd Allowed"
                          type="number"
                          label="No of Crowd Allowed"
                        />

                        <div>
                          <p className="block mt-3 text-[#074173] font-bold mb-1">
                            Staying Facilities:
                          </p>
                          <div className="flex items-center">
                            <div className="flex items-center">
                              <Field
                                type="radio"
                                name="staying_facilities"
                                value="Yes"
                              />
                              <label className="ml-2">Yes</label>
                            </div>
                            <div className="flex ml-3 items-center">
                              <Field
                                type="radio"
                                name="staying_facilities"
                                value="No"
                              />
                              <label className="ml-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                  <div>
                      <FieldArray name="remarks">
                        {({ push }) => (
                          <div>
                            <div className="flex  items-center">
                              <label className="block mb-1  font-bold text-[#074173]">
                                Remarks
                              </label>
                              <button
                                type="button"
                                onClick={() => push("")}
                                className="bg-[#074173] ml-3 p-1 text-lg text-[#FBFFFF] rounded-full"
                              >
                                <FaPlus />
                              </button>
                            </div>
                            {values.remarks.map((requirement, index) => (
                              <FormikInput
                                key={index}
                                name={`remarks[${index}]`}
                                placeholder={"Enter Remarks...."}
                              />
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>

                    <div className="mt-3">
                      <FieldArray name="termsAndCondition">
                        {({ push }) => (
                          <div>
                            <div className="flex  items-center">
                              <label className="block mb-1 font-bold text-[#074173]">
                                Terms and Condition
                              </label>
                              <button
                                type="button"
                                onClick={() => push("")}
                                className="bg-[#074173] ml-3 p-1 text-lg text-[#FBFFFF] rounded-full"
                              >
                                <FaPlus />
                              </button>
                            </div>
                            {values.termsAndCondition.map((requirement, index) => (
                              <FormikInput
                                key={index}
                                name={`termsAndCondition[${index}]`}
                                placeholder={"Enter Terms and Condition...."}
                              />
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                      </div>
                      <div>
                        <p className="block mt-3 text-[#074173] font-bold mb-1">
                          Location Type:
                        </p>
                        <div className="flex  items-center">
                          <div className="flex items-center">
                            <Field
                              type="radio"
                              name="location_type"
                              value="public"
                            />
                            <label className="ml-2">Public</label>
                          </div>
                          <div className="flex ml-3 items-center">
                            <Field
                              type="radio"
                              name="location_type"
                              value="private"
                            />
                            <label className="ml-2">Private</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5"></div>

                    <div className="md:grid sm:grid sm:grid-cols-2 grid grid-cols-1 md:gap-3 md:grid-cols-3">
                      <div className="border-2 p-2 rounded">
                        <p className="block text-lg text-[#074173] font-bold">
                          Nearby Bus Stop
                        </p>
                        <FormikInput
                          name="stop_name"
                          placeholder="Bus Stop Name"
                          type="text"
                          label="Bus Stop Name"
                        />
                        <FormikInput
                          name="stop_km"
                          placeholder="Distance (km)"
                          type="number"
                          label="Distance from Location (km)"
                        />
                      </div>
                      <div className="border-2 p-2 rounded">
                        <p className="block text-lg text-[#074173] font-bold">
                          Nearby Railway Station
                        </p>
                        <FormikInput
                          name="station_name"
                          placeholder="Station Name"
                          type="text"
                          label="Station Name"
                        />
                        <FormikInput
                          name="station_km"
                          placeholder="Distance (km)"
                          type="number"
                          label="Distance from Location (km)"
                        />
                      </div>
                      <div className="border-2 p-2 rounded">
                        <p className="block text-lg text-[#074173] font-bold">
                          Nearby Airport
                        </p>
                        <FormikInput
                          name="airport_name"
                          placeholder="Airport Name"
                          type="text"
                          label="Airport Name"
                        />
                        <FormikInput
                          name="airport_km"
                          placeholder="Distance (km)"
                          type="number"
                          label="Distance from Location (km)"
                        />
                      </div>
                    </div>

                    <p className="text-[#074173] mb-1 text-center mt-7 text-xl font-bold">
                      {" "}
                      Current Images
                    </p>
                    <div className="sm:flex sm:ml-14 sm:justify-between sm:w-[630px]">
                      <div>
                        <p className="text-[#074173] font-semibold">
                          Main Image
                        </p>
                        <img
                          className="h-[200px] shadow  w-[250px]"
                          src={location.image}
                        />
                      </div>
                      <div className="">
                        <p className="text-[#074173] font-semibold">
                          Additional Images
                        </p>
                        <div className="sm:grid gap-2 sm:grid-cols-2">
                          {location.additionalImages.map((imageUrl) => (
                            <div>
                              <button
                                onClick={() => {
                                  deleteImage(imageUrl);
                                }}
                                type="button"
                                className="bg-red-500 text-white px-2 rounded-full"
                              >
                                X
                              </button>
                              <img
                                key={imageUrl}
                                src={imageUrl}
                                className="h-[100px] shadow w-[150px]"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <p className="mt-7 text-[#074173] text-xl font-bold text-center">
                      Upload New Images
                    </p>
                    <div className="sm:flex sm:items-center sm:justify-center">
                      <div className="">
                        <label className="block text-[#074173] font-bold mb-2">
                          Update Location Image
                        </label>
                        <input
                          type="file"
                          onChange={(e) => setLocationImage(e.target.files[0])}
                        />
                      </div>
                      <div className="">
                        <label className="block text-[#074173] font-bold mb-2">
                          Upload Additional Images
                        </label>
                        <input
                          type="file"
                          multiple
                          onChange={(e) =>
                            setAdditionalImages([...e.target.files])
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-5">
                      <button
                        type="submit"
                        className="bg-[#074173] text-white font-bold py-2 px-4 rounded"
                      >
                        Update Location
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationUpdate;
