import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../FirebaseConfig";
import { Form, Formik } from "formik";
import FormikSelect from "../Components/FormikSelect";
import CityStateFiltering from "../AdminSide/CityStateFiltering";
import { Link } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function UserExploreLocations() {
  const [locationDetails, setLocationDetails] = useState([]);
  const [data, setData] = useState([]);
  const [filteringByCategory, setFilteringByCategory] = useState(null);
  const [suitable, setsuitable] = useState([]);
  const [filteringBySuitableFor, setfilteringBySuitableFor] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [storingByDefault, setstoringByDefault] = useState([]);
  const [shrinkingFilters, setshrinkingFilters] = useState(false);
  const [filteringByLocationType, setFilteringByLocationType] = useState(null);
  const [openingImages, setopeningImages] = useState(false);
  const [openingMoreImg, setOpeningMoreImg] = useState(null);
  const [displayCount, setDisplayCount] = useState(6);

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.key === "ArrowLeft") {
          sliderRef.current.slickPrev();
        } else if (event.key === "ArrowRight") {
          sliderRef.current.slickNext();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  async function getLocationDetails() {
    const querySnapshot = await getDocs(collection(db, "adding_location"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const sortingLocationDetails = multipleArray.sort((a, b) =>
      a.location_name.localeCompare(b.location_name)
    );

    let filteredArray = sortingLocationDetails;
    setstoringByDefault(filteredArray);

    if (filteringByCategory !== null) {
      filteredArray = filteredArray.filter((location) => {
        return (
          Array.isArray(location.locationCategory) &&
          location.locationCategory.includes(filteringByCategory)
        );
      });
    }

    if (filteringBySuitableFor !== null) {
      filteredArray = filteredArray.filter((location) => {
        return (
          Array.isArray(location.suitableFor) &&
          location.suitableFor.includes(filteringBySuitableFor)
        );
      });
    }

    if (selectedState !== null) {
      filteredArray = filteredArray.filter(
        (location) => selectedState === location.state
      );
    }

    if (selectedDistrict !== null) {
      filteredArray = filteredArray.filter(
        (location) => selectedDistrict === location.district
      );
    }

    if (filteringByLocationType !== null) {
      filteredArray = filteredArray.filter(
        (location) => location.location_type === filteringByLocationType
      );
    }

    setLocationDetails(filteredArray);
  }

  async function gettingLocationCategory() {
    const querySnapshot = await getDocs(collection(db, "location_category"));
    const category = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(category);
  }

  async function gettingSuitableFor() {
    const querySnapshot = await getDocs(collection(db, "suitable_for"));
    const suitable = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setsuitable(suitable);
  }

  useEffect(() => {
    getLocationDetails();
  }, [
    filteringBySuitableFor,
    filteringByCategory,
    selectedState,
    selectedDistrict,
    filteringByLocationType,
  ]);

  const handleImageClick = (location) => {
    const galleryItems = location.additionalImages.map((img) => ({
      original: img,
      thumbnail: img,
    }));
    setOpeningMoreImg({ ...location, galleryItems });
    setopeningImages(true);
  };

  useEffect(() => {
    gettingLocationCategory();
    gettingSuitableFor();
  }, []);

  return (
    <div className="">
      <div className="mt-10">
        <div>
          <div className="flex ml-2 sm:ml-0  items-center">
            <button
              className={`bg-[#074173] ${shrinkingFilters} rounded text-white sm:hidden py-1 px-2`}
              onClick={() => setshrinkingFilters(!shrinkingFilters)}
            >
              Filter
            </button>

            <p className=" ml-3 sm:ml-[260px] md:ml-[350px] lg:ml-[590px] sm:mt-5 text-3xl sm:text-4xl md:text-4xl text-[#074173] font-bold">
              Explore Locations
            </p>
          </div>

          <div
            className={`mt-6 sm:flex ${shrinkingFilters ? "block" : "hidden"} `}
          >
            <div className="flex sm:hidden px-5 justify-end">
              <button
                onClick={() => {
                  setshrinkingFilters(false);
                }}
                className="font-bold text-xl text-[#074173]"
              >
                X
              </button>
            </div>

            <div className="lg:flex">
              <div className=" sm:flex ml-24 md:ml-60 lg:ml-0 xl:ml-[93px]">
                <div className="flex  border-2 w-[175px] ml-2 sm:ml-10 lg:ml-0 sm:w-auto rounded-xl border-[#074173] items-center mt-2 mr-7">
                  <button
                    className={`rounded-tl-xl rounded-bl-xl  font-semibold px-2 py-[7px] text-[#074173] ${
                      filteringByLocationType === null
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setLocationDetails(storingByDefault);
                      setFilteringByCategory(null);
                      setfilteringBySuitableFor(null);
                      setSelectedState(null);
                      setSelectedDistrict(null);
                      setFilteringByLocationType(null);
                      setDisplayCount(6);
                    }}
                  >
                    All
                  </button>
                  <button
                    className={`font-semibold px-2 py-[7px] text-[#074173] ${
                      filteringByLocationType === "private"
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => setFilteringByLocationType("private")}
                  >
                    Private
                  </button>
                  <button
                    className={` text-[#074173]  font-semibold rounded-tr-xl rounded-br-xl px-2 py-[7px] ml-3 ${
                      filteringByLocationType === "public"
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => setFilteringByLocationType("public")}
                  >
                    Public
                  </button>
                </div>
                <Formik>
                  <div className="sm:w-full  w-[200px]">
                    <Form>
                      <div className="sm:flex  w-auto sm:justify-between">
                        <div>
                          <FormikSelect
                            name={"suitableFor"}
                            selectedItem={"select suitable for"}
                            options={suitable}
                            onChange={(e) => {
                              setfilteringBySuitableFor(e.target.value);
                            }}
                          />
                        </div>

                        <div className="sm:ml-3 ml-0">
                          <FormikSelect
                            name={"category_name"}
                            selectedItem={"select category"}
                            options={data}
                            onChange={(e) => {
                              setFilteringByCategory(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </Formik>
              </div>

              <div className="sm:flex md:ml-[280px] lg:ml-0  lg:w-auto ml-24 sm:ml-32  sm:items-center">
                <div>
                  <CityStateFiltering
                    onStateChange={setSelectedState}
                    onDistrictChange={setSelectedDistrict}
                  />
                </div>
                <div className="mt-[6px] sm:0  sm:ml-3 ml-14">
                  <button
                    onClick={() => {
                      setLocationDetails(storingByDefault);
                      setFilteringByCategory(null);
                      setfilteringBySuitableFor(null);
                      setSelectedState(null);
                      setSelectedDistrict(null);
                      setFilteringByLocationType(null);
                      setDisplayCount(6);
                    }}
                    className="bg-[#074173] rounded px-2 py-[7px] text-white"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 sm:mr-10 sm:ml-10 lg:ml-20 mt-3 lg:mr-20 sm:grid-cols-2 gap-5 grid-cols-1">
          {locationDetails.slice(0, displayCount).map((location) => (
            <div
              key={location.id}
              className=" m-2  pb-2 rounded shadow-xl bg-white"
            >
              {openingMoreImg && openingImages && (
                <div className="bg-black flex justify-center items-center fixed inset-0 z-50 bg-opacity-70">
                  <div className="fixed right-5 top-3">
                    <button
                      onClick={() => {
                        setOpeningMoreImg(null);
                        setopeningImages(false);
                      }}
                      className="text-3xl text-white"
                    >
                      X
                    </button>
                  </div>
                <div className=" ">
                <div className="md:mt-0 sm:w-[500px] md:w-[900px] w-[320px] bg-white rounded sm:rounded-xl sm:p-2">
                    <ReactImageGallery items={openingMoreImg.galleryItems} />
                  </div>
                </div>
                </div>
              )}

              <img
                onClick={() => handleImageClick(location)}
                src={location.image}
                className="h-48 w-full object-cover rounded"
                alt={location.location_name}
              />

              <div className="flex items-center justify-between mt-2">
                <p className="text-lg ml-3 text-[#074173] font-bold">
                  {location.location_name}
                </p>

                <Link to={`/DetailedLocation/${location.id}`} key={location.id}>
                  <button className="text-white   mr-3 px-2 rounded py-1 bg-[#074173]">
                    View More...
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-start mt-3">
          <div className="">
            {displayCount < locationDetails.length && (
              <button
                onClick={() => setDisplayCount(displayCount + 6)}
                className="text-xl sm:text-2xl mr-2 ml-4 sm:ml-10 lg:ml-20 text-[#074172] font-[200]"
              >
                More Locations.......
              </button>
            )}
          </div>
          <div>
            {displayCount > 6 && (
              <button
                onClick={() => setDisplayCount(6)}
                className=" text-xl sm:text-2xl ml-4 sm:ml-10 lg:ml-20 text-[#074173] font-[200]"
              >
                Less Locations.......
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserExploreLocations;
